import ActivityLogger from './components/ActivityLogger.vue';
import mixin from './mixin';
import DataLayer from './services/LayerService';
import store from './store/index';

export default {
  install(Vue, options) {
    if (!options || !options.store) {
      throw new Error('Please initialize plugin with a Vuex store.');
    }
    // Register modals vuex module
    options.store.registerModule('DL', store);
    // install Mixins
    Vue.mixin(mixin);
    // install components
    Vue.component(ActivityLogger);

    const db = new DataLayer(options.store);
    console.log(options.store, 'xxx');
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$DL = db;
  },
};
